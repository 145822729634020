import { Draft } from "immer";
import { IQuestCategory } from "../../../api/questionnaire/response";
import {
    IQuestionnaireRemovePayload,
    QuestionnaireLevel,
    QuestionnaireState,
    IQuestionnaireUpdatePayload,
    IQuestionnaireInsertPayload,
    IQuestionnaireMovePayload
} from "../types";
import { CategoryInitialization, SubCategoryInitialization, QuestionInitialization } from "../initialization";
import validationsReducer from "./validation";

function deletionByLevel(draft: Draft<QuestionnaireState>, payload: IQuestionnaireRemovePayload) {
    const {
        indexes: { category, subCategory, question },
        level
    } = payload;
    switch (level) {
        case QuestionnaireLevel.Category:
            draft.categories.splice(category!, 1);
            break;
        case QuestionnaireLevel.SubCategory:
            if (subCategory != null) {
                draft.categories[category!].subCategories.splice(subCategory, 1);
            }
            break;

        case QuestionnaireLevel.Question:
            if (subCategory != null && question != null) {
                draft.categories[category!].subCategories[subCategory].questions.splice(question, 1);
            }
            break;
    }
}

function updateByLevel(draft: Draft<QuestionnaireState>, payload: IQuestionnaireUpdatePayload) {
    const {
        indexes: { category, subCategory, question },
        level,
        data,
        field
    } = payload;
    validationsReducer.fieldValidation(draft, data, { indexes: payload.indexes, name: payload.field, level: payload.level });

    switch (level) {
        case QuestionnaireLevel.Category:
            (draft.categories[category!] as any)[field] = data;
            break;
        case QuestionnaireLevel.SubCategory:
            if (subCategory != null) {
                (draft.categories[category!].subCategories[subCategory] as any)[field] = data;
            }
            break;

        case QuestionnaireLevel.Question:
            if (subCategory != null && question != null) {
                (draft.categories[category!].subCategories[subCategory].questions[question] as any)[field] = field === "negativeGrade" || field === "positiveGrade" ? parseInt(data) : data;
            }
            break;
    }
}

function insertByLevel(draft: Draft<IQuestCategory[]>, payload: IQuestionnaireInsertPayload) {
    const {
        indexes: { category, subCategory },
        level
    } = payload;
    switch (level) {
        case QuestionnaireLevel.Category:
            draft.push(CategoryInitialization());
            break;
        case QuestionnaireLevel.SubCategory:
            draft[category!].subCategories.push(SubCategoryInitialization());
            break;
        case QuestionnaireLevel.Question:
            if (subCategory != null) {
                draft[category!].subCategories[subCategory].questions.push(QuestionInitialization());
            }
            break;
    }
}

function moveByLevel(draft: Draft<QuestionnaireState>, payload: IQuestionnaireMovePayload) {
    const {
        indexes: { category, subCategory },
        level,
        destination,
        source
    } = payload;
    switch (level) {
        case QuestionnaireLevel.Question:
            if (subCategory != null) {
                const questions = draft.categories[category!].subCategories[subCategory].questions;

                const [removed] = questions.splice(source, 1);
                questions.splice(destination, 0, removed);
            }
            break;
        case QuestionnaireLevel.SubCategory:
            if (category != null) {
                const subcategories = draft.categories[category!].subCategories;
                
                const [removed] = subcategories.splice(source, 1);
                subcategories.splice(destination, 0, removed);
            }
            break;
        case QuestionnaireLevel.Category:
            const categories = draft.categories;
            const [removed] = categories.splice(source, 1);
            categories.splice(destination, 0, removed);
            break;
    }
}

export default {
    moveByLevel,
    deletionByLevel,
    insertByLevel,
    updateByLevel
};
