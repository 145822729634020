export const QUESTIONNAIRE_INITIALIZATION_ACTION = "QUESTIONNAIRE_INITIALIZATION_ACTION"
export const QUESTIONNAIRE_DELETE_ACTION = "QUESTIONNAIRE_DELETE_ACTION"
export const QUESTIONNAIRE_UPDATE_ACTION = "QUESTIONNAIRE_UPDATE_ACTION"
export const QUESTIONNAIRE_INSERT_ACTION = "QUESTIONNAIRE_INSERT_ACTION"
export const QUESTIONNAIRE_MOVE_ACTION = "QUESTIONNAIRE_MOVE_ACTION"
export const QUESTIONNAIRE_RESET_ACTION = "QUESTIONNAIRE_RESET_ACTION"
export const QUESTIONNAIRE_REGISTER_VALIDATION_ACTION = "QUESTIONNAIRE_REGISTER_VALIDATION_ACTION"
export const QUESTIONNAIRE_SET_STATUS_ACTION = "QUESTIONNAIRE_SET_STATUS_ACTION"
export const QUESTIONNAIRE_FORM_VALIDATION_ACTION = "QUESTIONNAIRE_FORM_VALIDATION_ACTION"
export const QUESTIONNAIRE_FIELD_FOCUS_ACTION = "QUESTIONNAIRE_FIELD_FOCUS_ACTION"
export const QUESTIONNAIRE_UNREGISTER_VALIDATION_ACTION = "QUESTIONNAIRE_UNREGISTER_VALIDATION_ACTION"
export const QUESTIONNAIRE_UNREGISTER_ALL_VALIDATIONS = "QUESTIONNAIRE_UNREGISTER_ALL_VALIDATIONS"
export const QUESTIONNAIRE_SET_FORM_STATUS_ACTION = "QUESTIONNAIRE_SET_FORM_STATUS_ACTION"
export const QUESTIONNAIRE_APPEND_TEMPLATE_ACTION = "QUESTIONNAIRE_APPEND_TEMPLATE_ACTION"
export const QUESTIONNAIRE_REMOVE_TEMPLATE_ACTION = "QUESTIONNAIRE_REMOVE_TEMPLATE_ACTION"
export const QUESTIONNAIRE_TEMPLATE_STATUS_UPDATE_ACTION = "QUESTIONNAIRE_TEMPLATE_STATUS_UPDATE_ACTION"
export const QUESTIONNAIRE_APPEND_TEMPLATE_ID_ACTION = "QUESTIONNAIRE_APPEND_TEMPLATE_ID_ACTION"
