import { AppManagerState, AppManagerActionTypes } from "./types"
import { APP_STARTUP_LOADING, APP_STARTUP_COMPLETED, USER_AUTH_CHANGE, SAVE_SESSION_PERSISTOR, USER_SESSION_EXPIRED } from "./constants"

const initialState: AppManagerState = {
    isStartupCompleted: false,
    isStartupLoading: true,
    startupRequestsState: new Map(),
    authMode: null,
    isUserLoggedIn: false,
    isUserSessionExp: false,
    sessionPersistor: undefined
}

export default function appManagerReducer(state = initialState, action: AppManagerActionTypes): AppManagerState {
    switch (action.type) {
        case USER_AUTH_CHANGE: {
            const { isStartupCompleted, startupRequestsState, ...rest } = state
            const isLoggedIn = action.payload.isLoggedIn
            return {
                ...rest,
                authMode: action.payload.mode,
                isUserLoggedIn: action.payload.isLoggedIn,
                isStartupCompleted: !isLoggedIn ? false : isStartupCompleted,
                startupRequestsState: !isLoggedIn ? new Map() : startupRequestsState
            }
        }
        case USER_SESSION_EXPIRED:
            return {
                ...state,
                isUserSessionExp: action.payload
            }
        case SAVE_SESSION_PERSISTOR: {
            return {
                ...state,
                sessionPersistor: action.payload
            }
        }
        case APP_STARTUP_LOADING: {
            return {
                ...state,
                isStartupLoading: true
            }
        }
        case APP_STARTUP_COMPLETED: {
            return {
                ...state,
                isStartupCompleted: true,
                isStartupLoading: false,
                startupRequestsState: action.payload
            }
        }
        default:
            return state
    }
}
