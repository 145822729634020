import { Dispatch, AnyAction } from "redux"
import { AppState } from ".."
import { APP_STARTUP_COMPLETED, APP_STARTUP_LOADING, USER_AUTH_CHANGE, SAVE_SESSION_PERSISTOR } from "./constants"
import { AppManagerActionTypes } from "./types"
import { APIClient } from "../../configs"
import { insertUser, deleteUser } from "../user/actions"
import { ILoginResponse } from "../../api/user/response"
import { Persistor } from "redux-persist"

export const loginUser = (user: ILoginResponse) => {
    return (dispatch: Dispatch<any>, getState: () => AppState) => {
        dispatch(insertUser(user))
    }
}

export const logoutUser = (isAad?: boolean) => {
    return async (dispatch: Dispatch<AnyAction>, getState: () => AppState) => {
        const sessionPersistor = getState().appManager.sessionPersistor

        APIClient.RemoveAuthHeader()
        sessionPersistor && (await sessionPersistor.purge()) // purge store

        dispatch(deleteUser()) // reset user store
        dispatch({
            type: USER_AUTH_CHANGE,
            payload: { isLoggedIn: false, mode: null },
        })
    }
}

export const userDataLoaded = (persistor: Persistor) => {
    return (dispatch: Dispatch<any>, getState: () => AppState) => {
        const user = getState().user
        if (!isEmpty(user) && user.id != null) {
            // dispatch(_authenticateUserInApp(user.token!, "loaded"))
        }
        dispatch(_saveSessionPersistor(persistor))
    }
}

// TODO:
// -check what can be removed
// -show notifications of the items that couldn't be loaded (?)
export const loadApp = () => {
    return async (dispatch: Dispatch<any>, getState: AppState) => {
        dispatch({
            type: APP_STARTUP_LOADING,
        })

        const responses: Array<any> = await Promise.all([
            // dispatch(getTeams())
        ])
        const requestsState = new Map<string, boolean>(responses.map((r) => [r[0], r[1].Success]))

        dispatch({
            type: APP_STARTUP_COMPLETED,
            payload: { requestsState: requestsState },
        })
    }
}

const _saveSessionPersistor = (persistor: Persistor): AppManagerActionTypes => {
    return {
        type: SAVE_SESSION_PERSISTOR,
        payload: persistor,
    }
}

const isEmpty = (obj: any): boolean => {
    return Object.entries(obj).length === 0 && obj.constructor === Object
}
