import React from "react";
import { ErrorPage } from "../../pages/_support_/500/ErrorPage";

export class PageErrorBoundary extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = { error: null, errorInfo: null };
    }

    // TODO:
    // -report log errors to the api
    componentDidCatch(error: any, errorInfo: any) {
        this.setState({
            error: error,
            errorInfo: errorInfo
        });
    }

    render() {
        const { error, errorInfo } = this.state;
        if (error != null) {
            return <ErrorPage name={error} info={errorInfo} />;
        }

        return this.props.children;
    }
}
