import { USER_INSERT, USER_UPDATE, USER_DELETE } from "./constants"
import { IUserState, UserActionTypes } from "./types"

const initialState: IUserState = {
    id: undefined,
    token: undefined,
    name: undefined,
    roles: undefined,
    email: undefined,
    language: undefined,
    countries: undefined
}

export default (state = initialState, action: UserActionTypes): IUserState => {
    switch (action.type) {
        case USER_INSERT: {
            return action.payload.user
        }
        case USER_UPDATE: {
            return { ...state, ...action.payload.user }
        }
        case USER_DELETE: {
            return initialState
        }
        default:
            return state
    }
}
