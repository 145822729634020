import { IQuestCategory, IQuestSubCategory, IQuestQuestion } from "../../api/questionnaire/response"

export const CategoryInitialization = (): IQuestCategory => ({
    id: generateGUID(),
    code: "",
    name: "",
    description: "",
    subCategories: [SubCategoryInitialization()]
})

export const SubCategoryInitialization = (): IQuestSubCategory => ({
    id: generateGUID(),
    code: "",
    name: "",
    description: "",
    questions: [QuestionInitialization()]
    // questions: Array.from(Array(3).keys()).map(n => QuestionInitialization())
})

export const QuestionInitialization = (): IQuestQuestion => ({
    id: generateGUID(),
    text: "",
    scaleId: "",
    isBlackpoint: false,
    positiveGrade: 0,
    negativeGrade: 0
})

export const generateGUID = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
        const r = (Math.random() * 16) | 0,
            v = c === "x" ? r : (r & 0x3) | 0x8
        return v.toString(16)
    })
}
