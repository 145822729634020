import React, { useState } from "react"

export enum sizes {
    NORMAL,
    COMPACT,
    MOBILE
}

interface IGlobalContext {
    size: sizes | undefined
    setSize: (x: sizes) => void
}

export const GlobalContext = React.createContext<IGlobalContext>(null as any)

export const useGlobalContext = (): IGlobalContext => {
    const [size, setSize] = useState<sizes>()

    const globalContext = React.useMemo<IGlobalContext>(() => {
        return {
            size,
            setSize
        }
    }, [size])

    return globalContext
}