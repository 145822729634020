import axios, { AxiosInstance, AxiosRequestConfig } from "axios"
import querystring from "querystring"
import { ApiResponse } from "./types"

export class APIManager {
    private _client: AxiosInstance
    private aad: boolean = false

    constructor() {
        this._client = axios.create({
            method: "GET",
            baseURL: process.env.REACT_APP_API_URL,
        } as AxiosRequestConfig)
    }

    request = async <T>(url: string, config?: AxiosRequestConfig, qs?: any) => {
        const requestURL = qs ? this._client.defaults.baseURL + url + "?" + querystring.stringify(qs) : this._client.defaults.baseURL + url
        // const user = getAADUser()
        // if (user && this.aad) {
        //     this._client.defaults.headers.common.Authorization =
        //         "Bearer " + (await LoginAgent.acquireTokenSilent({ account: user, scopes: AADConfig.scopes })).accessToken
        // }

        return new Promise((resolve, reject) => {
            return this._client
                .request({ ...config, baseURL: requestURL })
                .then((response) => resolve({ Success: true, Data: response.data }))
                .catch((error) => {
                    if (error.response) {
                        resolve({ Success: false, Error: { Description: error.response.data, ErrorCode: error.response.status } })
                    } else {
                        resolve({ Success: false, Error: { Description: "api.not", ErrorCode: 504 } })
                    }
                })
        }) as Promise<ApiResponse<T>>
    }

    AddAuthHeader = (token: string) => {
        this._client.defaults.headers.common.Authorization = "Bearer " + token //LoginAgent.acquireTokenSilent({ scopes: AADConfig.scopes }) //"Bearer " + token
    }

    RemoveAuthHeader = () => {
        delete this._client.defaults.headers.common.Authorization
    }

    SetAAD = (isAAD: boolean) => {
        this.aad = isAAD
    }
}
