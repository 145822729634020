export const UserRole = ["ADMIN", "AUDITOR"]
export enum UserRoles {
    ADMIN,
    AUDITOR,
    SUPERADMIN,
}

export interface ILoginResponse {
    id: string
    token: string
    name: string
    firstName: string
    lastName: string
    roles: number[]
    email: string
    language: string
    countries: Array<IUserCountry>
    systemConfigs: ISystemConfigs
}

export type IUserList = Array<IUserListItem>

export interface IUserListItem {
    id: string
    firstName: string
    lastName: string
    email: string
    language: string
    isDeleted: boolean
    roles: Array<number>
    shoppings: Array<string>
}

export interface ISystemConfigs {
    azureStorageSAS?: string
    azureStorageURI?: string
}

export interface IUserCountry {
    id: string
    name: string
    languageCode: string
    shoppings: Array<{
        id: string
        name: string
        languageCode: string
        email?: string
        isICIM: boolean;
    }>
}
