import React, { lazy } from "react";

const DevPage = lazy(() => import("./dev/DevPage"));

export const ErrorPage = (props: any) => {

    if (process.env.NODE_ENV === "development") {
        return <DevPage stack={props.info.componentStack} />;
    }

    return (
        <div>
            <h1>Error Page</h1>
            <h3>Something went wrong.</h3>
            <code>{props.info.componentStack}</code>
        </div>
    );
};
