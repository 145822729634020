import { CHANGE_SHOPPING, TRANSLATIONS_INSERT, TRANSLATION_MESSAGE, SET_SYSTEMCONFIGS, CHANGE_LANGUAGE } from "./constants"
import { AppConfigsActionTypes } from "./types"
import { ISystemConfigs } from "../../api/user/response"

export const selectShopping = (userId: string, shoppingId: string, countryId: string, languageCode: string): AppConfigsActionTypes => {
    return {
        type: CHANGE_SHOPPING,
        payload: { userId, shoppingId, countryId, languageCode }
    }
}

export const selectLanguage = (language: string): AppConfigsActionTypes => {
    return {
        type: CHANGE_LANGUAGE,
        payload: { language: language }
    }
}

export const setTranslations = (data: any): AppConfigsActionTypes => {
    return {
        type: TRANSLATIONS_INSERT,
        payload: data
    }
}

export const getTranslation = (id: string): AppConfigsActionTypes => {
    return {
        type: TRANSLATION_MESSAGE,
        payload: { id: id }
    }
}

export const setSystemConfigsAction = (data: ISystemConfigs): AppConfigsActionTypes => {
    return {
        type: SET_SYSTEMCONFIGS,
        payload: data
    }
}
