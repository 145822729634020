import { combineReducers } from "redux";

import userReducer from "./user/reducers";
import questionnaireReducer from "./questionnaire/reducer/reducer";
import appConfigsReducer from "./app-configs/reducer";
import appManagerReducer from "./app-manager/reducer";

export default combineReducers({
    user: userReducer,
    questionnaire: questionnaireReducer,
    appManager: appManagerReducer,
    appConfigs: appConfigsReducer
});
