import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import * as serviceWorker from "./serviceWorker"

import { initializeIcons, loadTheme } from "office-ui-fabric-react"
import "office-ui-fabric-react/dist/css/fabric.css"
import "./index.scss"
import colors from "./assets/colors"
import { momentConfig } from "./configs/moment-config"

initializeIcons()
momentConfig()
loadTheme({
    semanticColors: {
        inputBorder: colors.white.dark,
    },
    palette: {
        themeDark: colors.black.main,
        themePrimary: colors.black.light, //icons default color
        neutralDark: colors.black.dark,
        themeLight: colors.buttons.disabled,
        neutralLighter: colors.white.dark,
        neutralLight: colors.buttons.disabled, //selected options
        themeDarkAlt: colors.black.dark,
    }
})
ReactDOM.render(<App />, document.getElementById("root"))

// if you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()
