import { AppConfigsState, AppConfigsActionTypes } from "./types"
import { CHANGE_SHOPPING, TRANSLATIONS_INSERT, TRANSLATION_MESSAGE, SET_SYSTEMCONFIGS, CHANGE_LANGUAGE } from "./constants"

const initialState: AppConfigsState = {
    selectedShopping: { shoppingId: undefined, countryId: undefined },
    language: navigator.language.substring(0, 2),
    systemConfigs: { azureStorageSAS: undefined, azureStorageURI: undefined },
    translations: null
}

export default function appManagerReducer(state = initialState, action: AppConfigsActionTypes): AppConfigsState {
    switch (action.type) {
        case CHANGE_SHOPPING: {
            localStorage.setItem(action.payload.userId!, JSON.stringify(action.payload));
            return { ...state, selectedShopping: action.payload }
        }
        case CHANGE_LANGUAGE: {
            return { ...state, language: action.payload.language }
        }
        case TRANSLATIONS_INSERT: {
            return { ...state, translations: action.payload }
        }
        case TRANSLATION_MESSAGE: {
            return state.translations && state.translations[action.payload.id] ? state.translations![action.payload.id] : ""
        }
        case SET_SYSTEMCONFIGS: {
            return { ...state, systemConfigs: action.payload }
        }
        default:
            return state
    }
}
