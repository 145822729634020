import produce, { setAutoFreeze } from "immer"
import { IQuestCategory } from "../../../api/questionnaire/response"
import { IFieldValidatorRegister } from "../../../components/questionnaire-core/hooks/useQuestionnaireValidation"
import {
    QUESTIONNAIRE_APPEND_TEMPLATE_ACTION,
    QUESTIONNAIRE_DELETE_ACTION,
    QUESTIONNAIRE_FIELD_FOCUS_ACTION,
    QUESTIONNAIRE_FORM_VALIDATION_ACTION,
    QUESTIONNAIRE_INITIALIZATION_ACTION,
    QUESTIONNAIRE_INSERT_ACTION,
    QUESTIONNAIRE_MOVE_ACTION,
    QUESTIONNAIRE_REGISTER_VALIDATION_ACTION,
    QUESTIONNAIRE_REMOVE_TEMPLATE_ACTION,
    QUESTIONNAIRE_SET_FORM_STATUS_ACTION,
    QUESTIONNAIRE_SET_STATUS_ACTION,
    QUESTIONNAIRE_UNREGISTER_VALIDATION_ACTION,
    QUESTIONNAIRE_UNREGISTER_ALL_VALIDATIONS,
    QUESTIONNAIRE_UPDATE_ACTION,
    QUESTIONNAIRE_TEMPLATE_STATUS_UPDATE_ACTION,
    QUESTIONNAIRE_RESET_ACTION,
    QUESTIONNAIRE_APPEND_TEMPLATE_ID_ACTION,
} from "../constants"
import {
    initialValidation,
    IQuestionnaireInsertPayload,
    IQuestionnaireMovePayload,
    IQuestionnaireRemovePayload,
    IQuestionnaireUpdatePayload,
    IValidationFieldNav,
    QuestionnaireActionTypes,
    QuestionnaireState,
    QuestionnaireStatus,
} from "../types"
import questionnaireReducer from "./questionnaire"
import validationReducer from "./validation"
setAutoFreeze(false)

const initialState: QuestionnaireState = {
    categories: [],
    templateCategoriesIds: [],
    validation: initialValidation,
    status: QuestionnaireStatus.INITIALIZED,
    isUpdatingTemplate: false,
}

export default function reducer(state = initialState, action: QuestionnaireActionTypes): QuestionnaireState {
    return produce(state, (draft) => {
        const { payload } = action
        switch (action.type) {
            case QUESTIONNAIRE_INITIALIZATION_ACTION:
                return {
                    ...initialState,
                    categories: payload as IQuestCategory[],
                }
            case QUESTIONNAIRE_SET_STATUS_ACTION:
                const status = payload as QuestionnaireStatus
                switch (status) {
                    case QuestionnaireStatus.INITIALIZED:
                        draft = initialState
                        break
                    case QuestionnaireStatus.RUNNING:
                        draft.status = QuestionnaireStatus.RUNNING
                        break
                    case QuestionnaireStatus.COMPLETED:
                        draft.status = QuestionnaireStatus.COMPLETED
                        break
                }
                break
            case QUESTIONNAIRE_INSERT_ACTION:
                questionnaireReducer.insertByLevel(draft.categories as IQuestCategory[], payload as IQuestionnaireInsertPayload)
                break
            case QUESTIONNAIRE_DELETE_ACTION:
                questionnaireReducer.deletionByLevel(draft as QuestionnaireState, payload as IQuestionnaireRemovePayload)
                break
            case QUESTIONNAIRE_UPDATE_ACTION:
                questionnaireReducer.updateByLevel(draft as QuestionnaireState, payload as IQuestionnaireUpdatePayload)
                break
            case QUESTIONNAIRE_MOVE_ACTION:
                questionnaireReducer.moveByLevel(draft as QuestionnaireState, payload as IQuestionnaireMovePayload)
                validationReducer.unregisterAllValidators(draft.validation, undefined)
                break
            case QUESTIONNAIRE_TEMPLATE_STATUS_UPDATE_ACTION:
                draft.isUpdatingTemplate = payload as boolean
                break
            case QUESTIONNAIRE_APPEND_TEMPLATE_ACTION:
                draft.categories.push(...(payload as IQuestCategory[]))
                draft.templateCategoriesIds = (payload as IQuestCategory[]).map((c) => c.id)
                break
            case QUESTIONNAIRE_APPEND_TEMPLATE_ID_ACTION:
                draft.templateCategoriesIds = (payload as IQuestCategory[]).map((c) => c.id)
                break
            case QUESTIONNAIRE_REMOVE_TEMPLATE_ACTION:
                let index= draft.categories.findIndex((c) => draft.templateCategoriesIds.find((t) => t === c.id))
                draft.categories = draft.categories.filter((c) => !draft.templateCategoriesIds.find((t) => t === c.id))
                if(index>0){
                    validationReducer.unregisterAllValidators(draft.validation, `category[${index}]`)
                }
                else{
                    validationReducer.unregisterAllValidators(draft.validation, undefined)
                }
                draft.templateCategoriesIds = []
                break
            case QUESTIONNAIRE_REGISTER_VALIDATION_ACTION:
                validationReducer.registerValidator(draft as QuestionnaireState, payload as IFieldValidatorRegister)
                break
            case QUESTIONNAIRE_UNREGISTER_VALIDATION_ACTION:
                validationReducer.unregisterValidator(draft.validation, payload as IValidationFieldNav)
                break
            case QUESTIONNAIRE_UNREGISTER_ALL_VALIDATIONS:
                validationReducer.unregisterAllValidators(draft.validation, payload as string | undefined)
                break
            case QUESTIONNAIRE_FIELD_FOCUS_ACTION:
                validationReducer.fieldFocus(draft.validation, payload as IValidationFieldNav)
                break
            case QUESTIONNAIRE_FORM_VALIDATION_ACTION:
                validationReducer.formValidation(draft)
                break
            case QUESTIONNAIRE_RESET_ACTION:
                return initialState
            case QUESTIONNAIRE_SET_FORM_STATUS_ACTION:
                break
        }
    })
}
