import React from 'react';

(global as any).appVersion = process.env.REACT_APP_VERSION;

const semverGreaterThan = (versionA: string, versionB: string) => {
    const versionsA = versionA.split(/\./g);

    const versionsB = versionB.split(/\./g);
    while (versionsA.length || versionsB.length) {
        const a = Number(versionsA.shift());

        const b = Number(versionsB.shift());

        if (a === b) {
            continue;
        }

        return a > b || isNaN(b);
    }
    return false;
};

class CacheBuster extends React.Component {
    componentDidMount() {
        fetch('/meta.json')
            .then((response) => response.json())
            .then((meta) => {
                const latestVersion = meta.version;
                const currentVersion = (global as any).appVersion;

                const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion);
                if (shouldForceRefresh) {
                    console.log(`We have a new version - ${latestVersion}. Forcing refresh`);
                    if (caches) {
                        // Service worker cache should be cleared with caches.delete()
                        caches.keys().then(function (names) {
                            for (let name of names) caches.delete(name);
                        });
                    }
                    // delete browser cache and hard reload
                    window.location.reload();
                } else {
                    console.log(`You already have the latest version - ${latestVersion}. No cache refresh needed.`);
                }
            });
    }
    render() {
        return this.props.children;
    }
}

export default CacheBuster;