import { UserActionTypes, IUserState } from "./types";
import { USER_INSERT, USER_UPDATE, USER_DELETE } from "./constants";

export const insertUser = (user: IUserState) => {
    return {
        type: USER_INSERT,
        payload: { user }
    };
};

export const updateUser = (user: IUserState): UserActionTypes => {
    return {
        type: USER_UPDATE,
        payload: { user }
    };
};

export const deleteUser = (): UserActionTypes => {
    return {
        type: USER_DELETE
    };
};
