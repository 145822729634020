import lodash from "lodash"
import { IQuestCategory } from "../../api/questionnaire/response"
import { QuestionnaireIndexes } from "../../components/questionnaire-core/templates-questionnaire/interface"
import { IValidationField, QuestionnaireLevel } from "./types"

export const buildFieldName = (name: string, indexes: QuestionnaireIndexes, level: QuestionnaireLevel) => {
    switch (level) {
        case QuestionnaireLevel.Category:
            return `category[${indexes.category}].${name}`
        case QuestionnaireLevel.SubCategory:
            return `category[${indexes.category}].subCategory[${indexes.subCategory}].${name}`
        case QuestionnaireLevel.Question:
            return `category[${indexes.category}].subCategory[${indexes.subCategory}].question[${indexes.question}].${name}`
    }
}

export const buildFieldRoot = (indexes: QuestionnaireIndexes, level: QuestionnaireLevel, nextFieldReference: string = "") => {
    switch (level) {
        case QuestionnaireLevel.Category:
            return `category[${indexes.category}]` + nextFieldReference
        case QuestionnaireLevel.SubCategory:
            return `category[${indexes.category}].subCategory[${indexes.subCategory}]` + nextFieldReference
        case QuestionnaireLevel.Question:
            return `category[${indexes.category}].subCategory[${indexes.subCategory}].question[${indexes.question}]` + nextFieldReference
    }
}

export function getValue(props: { categories: IQuestCategory[]; indexes: QuestionnaireIndexes; level: QuestionnaireLevel; name: string }) {
    const {
        indexes: { category, subCategory, question },
        level,
        name,
        categories
    } = props
    switch (level) {
        case QuestionnaireLevel.Category:
            if (category != null) {
                return (categories[category!] as any)[name]
            }
            break
        case QuestionnaireLevel.SubCategory:
            if (category != null && subCategory != null) {
                return (categories[category!].subCategories[subCategory!] as any)[name]
            }
            break
        case QuestionnaireLevel.Question:
            if (category != null && subCategory != null && question != null) {
                return (categories[category!].subCategories[subCategory!].questions[question!] as any)[name]
            }
            break
    }
}

export function questionnaireValidateField(value: any, field: IValidationField) {
    const _requiredResult = (field: IValidationField) => {
        const { isRequired } = field.params
        if (!isRequired) {
            return false
        }

        return lodash.isEmpty(value)
    }

    const _executeValidationsResult = (field: IValidationField): [boolean, Array<string> | null] => {
        const { validations } = field.params
        if (validations == null) {
            return [false, null]
        }

        const results = validations.map(v => v(value)).filter(r => !r[0])
        if (results.length > 0) {
            return [true, results.map(x => x[1])]
        }
        return [false, null]
    }

    const _validationsResult = (field: IValidationField): Array<string> | null => {
        const required = _requiredResult(field)
        const validations = _executeValidationsResult(field)
        let errors: Array<string> = []

        if (required || validations[0]) {
            if (required) {
                errors.push("Required")
            }
            if (validations[0]) {
                errors = errors.concat(validations[1]!)
            }
            return errors
        }
        return null
    }
    if (field == null) {
        return null
    }
    return _validationsResult(field)
}

export function applyTransform(
    categories: IQuestCategory[],
    newValue: any,
    nav: { level: QuestionnaireLevel; indexes: QuestionnaireIndexes; name: string }
) {
    const {
        indexes: { category, subCategory, question },
        level,
        name
    } = nav

    switch (level) {
        case QuestionnaireLevel.Category:
            (categories[category!] as any)[name] = newValue
            break
        case QuestionnaireLevel.SubCategory:
            if (subCategory != null) {
                (categories[category!].subCategories[subCategory] as any)[name] = newValue
            }
            break

        case QuestionnaireLevel.Question:
            if (subCategory != null && question != null) {
                (categories[category!].subCategories[subCategory].questions[question] as any)[name] = newValue
            }
            break
    }
    return categories
}
