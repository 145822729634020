export const languages: ILang = [
    { code: "de", name: "Deutsch" },
    { code: "en", name: "English" },
    { code: "es", name: "Español" },
    { code: "fr", name: "Français" },
    { code: "it", name: "Italiano" },
    { code: "pl", name: "Polski" },
    { code: "pt", name: "Português" },
    { code: "sk", name: "Slovenský" },
    { code: "fi", name: "Suomi" },
    { code: "sv", name: "Svenska" },
    { code: "cs", name: "český" },
    { code: "hr", name: "Hrvatska" },
]

type ILang = Array<{
    code: string
    name: string
}>
