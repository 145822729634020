import { PublicClientApplication } from "@azure/msal-browser"

export const AADConfig = {
    appId: "6f67b917-890c-4a6e-bedd-2dc2d8cbe4b5",
    tenatID: "720b637a-655a-40cf-816a-f22f40755c2c",
    scopes: ["api://6f67b917-890c-4a6e-bedd-2dc2d8cbe4b5/access_as_user openid"],
}

export const LoginAgent = new PublicClientApplication({
    auth: {
        clientId: AADConfig.appId,
        redirectUri: window.location.origin,
        authority: "https://login.microsoftonline.com/" + AADConfig.tenatID,
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: true,
    },
})
