import React from "react";
import "./styles.scss";
import { DefaultButton, Icon } from "office-ui-fabric-react";
import styled from "@emotion/styled";
import { withRouter, RouteComponentProps } from "react-router-dom";

export const NotFoundPage = withRouter((props: RouteComponentProps) => {
    const { history } = props;
    return (
        <div className="container-404">
            <div className="wrapper">
                <h1>404</h1>
                <h3>Page was not found.</h3>
                <p>It seems that the page you're looking for doesn't exist.</p>
                <div className="buttons" />
                <Button onClick={() => history.go(-1)}>
                    <Icon iconName="DecreaseIndentArrow" style={{ fontSize: "2em" }} /> <IconText>GO BACK</IconText>
                </Button>
                <Button onClick={() => history.push("/")}>
                    <Icon iconName="Home" style={{ fontSize: "1.3em", marginRight: 10 }} /> <IconText>HOME</IconText>
                </Button>
            </div>
        </div>
    );
});

const Button = styled(DefaultButton)`
    padding: 20px;
    & + & {
        margin-left: 30px;
    }
`;

const IconText = styled.span`
    color: #000;
    font-size: 1em;
    font-weight: 600;
`;
