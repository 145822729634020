import moment from "moment"
import { timeStrings } from "../assets/translations/time-strings"

export const momentConfig = function() {
    for (let key in timeStrings)
        moment.updateLocale(key, {
            months: timeStrings[key].months,
            weekdays: timeStrings[key].weekdays
        })
}

export const getLangDate = function(lang: string) {
    for (let key in timeStrings) if (key === lang) return lang
    return "en"
}
