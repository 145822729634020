const colors = {
    primary: {
        main: "#0058A3",
        light: "#B0D7EC",
        dark: "#5488C7" //lighter than main
    },
    buttons: {
        main: "#FFDB00",
        hover: "#FDC300",
        disabled: "#D7CFC6"
    },
    secondary: {
        main: "#3f6ec7",
        light: "#80aafa",
        dark: "#2652a5"
    },
    white: {
        main: "#F7F4EF",
        light: "#ffffff",
        dark: "#EAE3DA"
    },
    black: {
        main: "#474747",
        light: "#767571",
        dark: "#333333"
    },
    red: {
        main: "#E30613",
        light: "#F4A698",
        dark: "#EF776E" //lighter than main
    },
    green: {
        main: "#00A366",
        light: "#8EBDA7",
        lighter: "#C0D9CA",
        dark: "#05676A",
    },
    yellow: {
        main: "#f2c306",
        light: "#ffd321",
        dark: "#d6ab01"
    },
    transparent: "#0000"
}

export default colors
