import { LoginAgent } from "../configs/aad-config"

//Get the prefered language out of a Dictionary, or else whatever is available
export const getLanguageText = (name: { [key: string]: string }, preferedLang: string): string => {
    const keys = Object.keys(name)
    if (keys.length == 0) return ""

    if (name[preferedLang]) return name[preferedLang]
    if (name["en"]) return name["en"]
    return name[keys[0]]
}

export const ArrayIsNullOrEmpty = (array: any[] | null | undefined): boolean => {
    if (array) {
        return array.length === 0
    }
    return true
}

export const getAADUser = () => {
    const accounts = LoginAgent.getAllAccounts()
    if (accounts === null) return null
    if (accounts.length === 0) return null
    return accounts[0]
}

export const parseJwt = (token: string) => {
    var base64Url = token.split(".")[1]
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/")
    var jsonPayload = decodeURIComponent(
        atob(base64)
            .split("")
            .map(function (c) {
                return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2)
            })
            .join("")
    )

    return JSON.parse(jsonPayload)
}

export const isvalidToken = (token?: string) => {
    try {
        if (token) {
            var jwt = parseJwt(token);
            if (!(Date.now() >= jwt.exp * 1000)) {
                return true;
            }
        }
    } catch (error) {}

    return false;
}

export const toBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = error => reject(error);
    });
}