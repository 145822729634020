import { createStore, applyMiddleware, Middleware } from "redux"
import { persistStore, persistReducer, PersistConfig, createMigrate } from "redux-persist"
import { createFilter } from "redux-persist-transform-filter"
import localForage from "localforage"

import rootReducer from "./reducers-config"
import thunk from "redux-thunk"
import { migrations } from "./migrations"

let middleWares = [thunk] as Middleware<any>[]

// if (process.env.NODE_ENV === "development") {
//     const createLogger = require("redux-logger").createLogger
//     middleWares = [...middleWares, createLogger()]
// }

const saveSelectedShoppingFilter = createFilter("appConfigs", ["selectedShopping", "language", "systemConfigs"])

const persistConfig: PersistConfig = {
    key: "root",
    version: 0,
    storage: localForage,
    throttle: 1000, // ms
    migrate: createMigrate(migrations, { debug: false }),
    whitelist: ["user", "appConfigs"],
    transforms: [saveSelectedShoppingFilter]
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const configureStore = (onComplete: any = () => {}) => {
    const store = createStore(persistedReducer, applyMiddleware(...middleWares))
    const persistor = persistStore(store, undefined, onComplete)
    return { store, persistor }
}

export type AppState = ReturnType<typeof rootReducer>
