import React, { lazy } from "react"
import { useSelector } from "react-redux"
import { Redirect, Route, RouteProps, Switch } from "react-router-dom"
import { UserRoles } from "./api/user/response"
import { NotFoundPage } from "./pages/_support_/404/NotFoundPage"
import { AppState } from "./store"
import { isvalidToken } from "./utils/utils";

const CountryTemplateCreate = lazy(() => import("./pages/country-template/Create"))
const CountryTemplateEdit = lazy(() => import("./pages/country-template/Edit"))
const CountryTemplateList = lazy(() => import("./pages/country-template/List"))
const HomePage = lazy(() => import("./pages/home/HomePage"))
const QuestionnaireEditPage = lazy(() => import("./pages/questionnaire/Edit"))
const QuestionnaireCreatePage = lazy(() => import("./pages/questionnaire/Create"))
const QuestionnaireListPage = lazy(() => import("./pages/questionnaire/List"))
const TeamListPage = lazy(() => import("./pages/teams/TeamListPage"))
const CountryListPage = lazy(() => import("./pages/countries/CountryListPage"))
const ScalesListPage = lazy(() => import("./pages/scales/ScalesListPage"))
const LoginPage = lazy(() => import("./pages/auth/LogInPage"))
const RedirectPage = lazy(() => import("./pages/redirect/RedirectPage"))
const RegisterPage = lazy(() => import("./pages/auth/RegisterPage"))
const RecoverPassPage = lazy(() => import("./pages/auth/RecoverPassPage"))
const AuditProfilePage = lazy(() => import("./pages/audits/AuditProfilePage"))
const AuditListPage = lazy(() => import("./pages/audits/AuditListPage"))
const StoreListPage = lazy(() => import("./pages/stores/StoreListPage"))
const CommentTemplateList = lazy(() => import("./pages/comment-template/CommentTemplatesListPage"))
const UserListPage = lazy(() => import("./pages/users/UserListPage"))
const ReportIndexPage = lazy(() => import("./pages/reports/Index"))
const UserProfile = lazy(() => import("./pages/users/UserProfilePage"))
const PrivacyPolicyPage = lazy(() => import("./pages/policy/PrivacyPolicy"))
const StoresCategoryListPage = lazy(() => import("./pages/stores-category/StoresCategoryListPage"))
const AuditCreateWizardForm = lazy(() => import("./pages/audits/components/CreateWizard"))
const UnitListPage = lazy(() => import("./pages/units/UnitListPage"))
const CountryCommentTemplateList = lazy(() => import("./pages/comment-template/CountryCommentTemplateListPage"))

// @remove role MasterAdmin
export const Routes: React.FC = () => {
    const keepFilter = window.localStorage.getItem("keepFilter");
    if(keepFilter){
        window.localStorage.setItem("keepFilter", "");
    }
    return (
        <Switch>
            <AuthorizedRoute exact={true} path="/" component={HomePage} />
            <Route path="/login" component={LoginPage} />
            <Route path="/redirect" component={RedirectPage} />
            <Route path="/recoverpass/:email/:token" component={RecoverPassPage} />
            <AuthorizedRoute roles={[UserRoles.ADMIN]} path="/register" component={RegisterPage} />
            <AuthorizedRoute roles={[UserRoles.ADMIN]} path="/questionnaire/create" component={QuestionnaireCreatePage} />
            <AuthorizedRoute roles={[UserRoles.ADMIN]} path="/questionnaire/edit/:id" component={QuestionnaireEditPage} />
            <AuthorizedRoute roles={[UserRoles.ADMIN]} path="/questionnaire/list" component={QuestionnaireListPage} />
            <AuthorizedRoute
                roles={[UserRoles.ADMIN]}
                path="/scale/create"
                component={(props: any) => <ScalesListPage {...props} create />}
            />
            <AuthorizedRoute roles={[UserRoles.ADMIN]} path="/scale/edit/:id" component={ScalesListPage} />
            <AuthorizedRoute roles={[UserRoles.ADMIN]} path="/scale/list" component={ScalesListPage} />
            <AuthorizedRoute
                roles={[UserRoles.ADMIN]}
                path="/commenttemplate/create"
                component={(props: any) => <CommentTemplateList {...props} create />}
            />
            <AuthorizedRoute roles={[UserRoles.ADMIN]} path="/commenttemplate/edit/:id" component={CommentTemplateList} />
            <AuthorizedRoute roles={[UserRoles.ADMIN]} path="/commenttemplate/list" component={CommentTemplateList} />
            <AuthorizedRoute roles={[UserRoles.ADMIN]} path="/country/commenttemplate/edit/:id" component={CountryCommentTemplateList} />
            <AuthorizedRoute roles={[UserRoles.ADMIN]} path="/country/commenttemplate/list" component={CountryCommentTemplateList} />
            <AuthorizedRoute roles={[UserRoles.ADMIN]} path="/country" component={CountryListPage} />
            <AuthorizedRoute roles={[UserRoles.ADMIN]} path="/team" component={TeamListPage} />
            <AuthorizedRoute path="/audit/profile/:id?" component={AuditProfilePage} />
            <AuthorizedRoute path="/audit/list" component={AuditListPage} />
            <AuthorizedRoute roles={[UserRoles.ADMIN]} path="/store/list" component={StoreListPage} />
            <AuthorizedRoute
                roles={[UserRoles.ADMIN]}
                path="/store/create"
                component={(props: any) => <CountryListPage {...props} create />}
            />
            <AuthorizedRoute roles={[UserRoles.ADMIN]} path="/store/edit/:id" component={StoreListPage} />
            <AuthorizedRoute roles={[UserRoles.ADMIN]} path="/storescategory/list" component={StoresCategoryListPage} />
            <AuthorizedRoute
                roles={[UserRoles.ADMIN]}
                path="/storescategory/create"
                component={(props: any) => <CountryListPage {...props} create />}
            />
            <AuthorizedRoute roles={[UserRoles.ADMIN]} path="/storescategory/edit/:id" component={StoresCategoryListPage} />
            <AuthorizedRoute roles={[UserRoles.ADMIN]} exact={true} path="/countrytemplate/create" component={CountryTemplateCreate} />
            <AuthorizedRoute roles={[UserRoles.ADMIN]} path="/countrytemplate/edit/:id" component={CountryTemplateEdit} />
            <AuthorizedRoute roles={[UserRoles.ADMIN]} exact={true} path="/countrytemplate/list" component={CountryTemplateList} />
            <AuthorizedRoute roles={[UserRoles.ADMIN]} exact={true} path="/user" component={UserListPage} />
            <AuthorizedRoute roles={[UserRoles.ADMIN]} path="/report" component={ReportIndexPage} />
            <Route path="/privacy" component={PrivacyPolicyPage} />
            <AuthorizedRoute roles={[UserRoles.ADMIN]} path="/userProfile" component={UserProfile} />
            <AuthorizedRoute roles={[UserRoles.ADMIN]} path="/audit/creationWizard" component={AuditCreateWizardForm} />
            <AuthorizedRoute roles={[UserRoles.ADMIN]} path="/unit/create" component={UnitListPage} />
            <AuthorizedRoute roles={[UserRoles.ADMIN]} path="/unit/edit/:id" component={UnitListPage} />
            
            <AuthorizedRoute roles={[UserRoles.ADMIN]} path="/unit/list" component={UnitListPage} />
            <Route path="*" component={NotFoundPage} />
        </Switch>
    )
}

// todo:
// -check useSelector performance

/**
 * Routes that require a log in and optionally some roles
 */
const AuthorizedRoute = ({ roles, preventAAD, ...rest }: { roles?: number[], preventAAD?: boolean } & RouteProps) => {
    const userState = useSelector((state: AppState) => state.user)
    const isAuthenticated = isvalidToken(userState.token)

    let hasRightRoles = roles && userState.roles ? roles.every((r) => userState.roles!.findIndex((rr) => rr == r) != -1) : true

    if (preventAAD) {
        hasRightRoles = false;
    }

    if (isAuthenticated && hasRightRoles) {
        return <Route {...rest} />
    } else if (isAuthenticated && !hasRightRoles) {
        return (
            <Redirect
                from={rest.path!.toString()}
                to={{ pathname: "/", state: { referrer: window.location.pathname + window.location.search } }}
            />
        )
    } else {
        return (
            <Redirect
                from={rest.path!.toString()}
                to={{ pathname: "/login", state: { referrer: window.location.pathname + window.location.search } }}
            />
        )
    }
}
