import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { AppState } from "../../store"
import { logoutUser } from "../../store/app-manager/actions"
import { parseJwt } from "../../utils/utils"

export const UserTokenGate: React.FC = () => {
    const dispach = useDispatch()
    const user = useSelector((state: AppState) => state.user)
    const { token } = user

    useEffect(() => {
        if (token && parseJwt(token).exp * 1000 <= Date.now()) {
            dispach(logoutUser())
        }
    }, [user])

    return <></>
}
