import styled from "@emotion/styled"
import React, { Suspense, useMemo, useLayoutEffect } from "react"
// @ts-ignore
import ReactNotification from "react-notifications-component"
import { Provider } from "react-redux"
import { BrowserRouter as Router } from "react-router-dom"
import { PersistGate } from "redux-persist/integration/react"
import { PageErrorBoundary } from "./components/utils/PageErrorBoundary"
import Intl from "./configs/intl"
import { LoadingPage } from "./pages/_support_/loading/LoadingPage"
import { Routes } from "./routes"
import { configureStore } from "./store"
import { GlobalContext, sizes, useGlobalContext } from "./configs/global-context"
import { APIClient } from "./configs"
import { parseJwt } from "./utils/utils"
import { UserTokenGate } from "./components/utils/UserTokenGate"
import CacheBuster from "./CacheBuster";
import {library} from "@fortawesome/fontawesome-svg-core"
import {fab} from "@fortawesome/free-brands-svg-icons"
import {faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'

library.add(
    fab,
    faPlus,
    faMinus
)

export const { store, persistor } = configureStore()

const App: React.FC = () => {
    const Notifications = useMemo(() => <ReactNotification key="notifications" options={{zIndex: 100000}} />, [])
    const globalContext = useGlobalContext()

    useLayoutEffect(() => {
        const checkSize = () => {
            if (window.innerWidth < 800) globalContext.setSize(sizes.MOBILE)
            else if (window.innerWidth < 1600) globalContext.setSize(sizes.COMPACT)
            else globalContext.setSize(sizes.NORMAL)
        }

        window.addEventListener("resize", checkSize)
        checkSize()
        return () => window.removeEventListener("resize", checkSize)
    }, [globalContext])

    const handleBeforeLogin = () => {
        const { token } = store.getState().user
        if (token && parseJwt(token).exp * 1000 >= Date.now()) APIClient.AddAuthHeader(token)
    }

    return (
        <CacheBuster>
            <Provider store={store}>
                <Suspense fallback={<LoadingPage />}>
                    <PersistGate persistor={persistor} onBeforeLift={handleBeforeLogin}>
                        {(bootstrapped: boolean) => {
                            return bootstrapped ? (
                                <Intl>
                                    <GlobalContext.Provider value={globalContext}>
                                        <PageErrorBoundary>
                                            <Router>
                                                <Main>
                                                    <Routes />
                                                </Main>
                                            </Router>
                                        </PageErrorBoundary>
                                    </GlobalContext.Provider>
                                    <UserTokenGate />
                                </Intl>
                            ) : (
                                <></>
                            )
                        }}
                    </PersistGate>
                    {Notifications}
                </Suspense>
            </Provider>
        </CacheBuster>
    )
}

const Main: React.FC = styled.main`
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
`

export default App
