import { IQuestCategory } from "../../api/questionnaire/response"
import { IFieldValidatorRegister } from "../../components/questionnaire-core/hooks/useQuestionnaireValidation"
import { QuestionnaireIndexes } from "../../components/questionnaire-core/templates-questionnaire/interface"
import {
    QUESTIONNAIRE_DELETE_ACTION,
    QUESTIONNAIRE_FIELD_FOCUS_ACTION,
    QUESTIONNAIRE_FORM_VALIDATION_ACTION,
    QUESTIONNAIRE_INITIALIZATION_ACTION,
    QUESTIONNAIRE_INSERT_ACTION,
    QUESTIONNAIRE_MOVE_ACTION,
    QUESTIONNAIRE_REGISTER_VALIDATION_ACTION,
    QUESTIONNAIRE_SET_STATUS_ACTION,
    QUESTIONNAIRE_UPDATE_ACTION,
    QUESTIONNAIRE_UNREGISTER_VALIDATION_ACTION,
    QUESTIONNAIRE_UNREGISTER_ALL_VALIDATIONS,
    QUESTIONNAIRE_SET_FORM_STATUS_ACTION,
    QUESTIONNAIRE_APPEND_TEMPLATE_ACTION,
    QUESTIONNAIRE_REMOVE_TEMPLATE_ACTION,
    QUESTIONNAIRE_TEMPLATE_STATUS_UPDATE_ACTION,
    QUESTIONNAIRE_RESET_ACTION,
    QUESTIONNAIRE_APPEND_TEMPLATE_ID_ACTION
} from "./constants"

export interface QuestionnaireState {
    categories: IQuestCategory[]
    templateCategoriesIds: string[]
    validation: IQuestionnaireValidation
    status: QuestionnaireStatus
    isUpdatingTemplate: boolean
}

export const initialValidation: IQuestionnaireValidation = {
    form: {
        isValid: false,
        submitting: false,
        submitFailed: false,
        submitSucceeded: false
    },
    fields: {}
}
export enum QuestionnaireStatus {
    INITIALIZED,
    RUNNING,
    COMPLETED
}

export interface IQuestionnaireValidation {
    form: IValidationForm
    fields: {
        [key: string]: IValidationField
    }
}
interface IValidationForm {
    isValid: boolean
    submitting: boolean
    submitFailed: boolean
    submitSucceeded: boolean
}

export interface IValidationField {
    nav: IValidationFieldNav
    params: IValidationFieldParams
}
export interface IValidationFieldNav {
    indexes: QuestionnaireIndexes
    level: QuestionnaireLevel
    name: string
}

export interface IValidationFieldParams {
    validated: boolean
    isValid: boolean
    errors?: string
    touched: boolean
    validations?: [(value: any) => [boolean, string]]
    isRequired?: boolean
    transformAfterInitialization?: (value: any) => any
    transformBeforeInitialization?: (value: any) => any
}

export enum QuestionnaireLevel {
    Category,
    SubCategory,
    Question
}

export interface IQuestionnaireSetStatusAction {
    type: typeof QUESTIONNAIRE_SET_STATUS_ACTION
    payload: QuestionnaireStatus
}

interface IQuestionnaireChangePayload {
    level: QuestionnaireLevel
    indexes: QuestionnaireIndexes
}

export interface IQuestionnaireInitilizeAction {
    type: typeof QUESTIONNAIRE_INITIALIZATION_ACTION
    payload: IQuestCategory[]
}

export interface IQuestionnaireRemoveAction {
    type: typeof QUESTIONNAIRE_DELETE_ACTION
    payload: IQuestionnaireRemovePayload
}

export interface IQuestionnaireUpdateAction {
    type: typeof QUESTIONNAIRE_UPDATE_ACTION
    payload: IQuestionnaireUpdatePayload
}
export interface IQuestionnaireInsertAction {
    type: typeof QUESTIONNAIRE_INSERT_ACTION
    payload: IQuestionnaireInsertPayload
}

export interface IQuestionnaireMoveAction {
    type: typeof QUESTIONNAIRE_MOVE_ACTION
    payload: IQuestionnaireMovePayload
}
export interface IQuestionnaireRemovePayload extends IQuestionnaireChangePayload {}
export interface IQuestionnaireInsertPayload extends IQuestionnaireChangePayload {}
export interface IQuestionnaireUpdatePayload extends IQuestionnaireChangePayload {
    field: any
    data: any
}

export interface IQuestionnaireMovePayload extends IQuestionnaireChangePayload {
    destination: number
    source: number
}
//TEMPLATE

export interface IQuestionnaireTemplateStatusUpdateAction {
    type: typeof QUESTIONNAIRE_TEMPLATE_STATUS_UPDATE_ACTION
    payload: boolean
}

export interface IQuestionnaireAppendTemplateAction {
    type: typeof QUESTIONNAIRE_APPEND_TEMPLATE_ACTION
    payload: IQuestCategory[]
}

export interface IQuestionnaireAppendIdTemplateAction {
    type: typeof QUESTIONNAIRE_APPEND_TEMPLATE_ID_ACTION
    payload: IQuestCategory[]
}

export interface IQuestionnaireRemoveTemplateAction {
    type: typeof QUESTIONNAIRE_REMOVE_TEMPLATE_ACTION
    payload: null
}

export interface IQuestionnaireReset {
    type: typeof QUESTIONNAIRE_RESET_ACTION
    payload: null
}

//FORM
export interface IQuestionnaireRegisterValidationAction {
    type: typeof QUESTIONNAIRE_REGISTER_VALIDATION_ACTION
    payload: IFieldValidatorRegister
}

export interface IQuestionnaireFormValidationAction {
    type: typeof QUESTIONNAIRE_FORM_VALIDATION_ACTION
    payload: null
}

export interface IQuestionnaireFieldFocusAction {
    type: typeof QUESTIONNAIRE_FIELD_FOCUS_ACTION
    payload: IValidationFieldNav
}

export interface IQuestionnaireUnregisterValidationAction {
    type: typeof QUESTIONNAIRE_UNREGISTER_VALIDATION_ACTION
    payload: IValidationFieldNav
}

export interface IQuestionnaireUnregisterAllValidations {
    type: typeof QUESTIONNAIRE_UNREGISTER_ALL_VALIDATIONS
    payload: string | undefined
}

export interface IQuestionnaireSetFormStatusAction {
    type: typeof QUESTIONNAIRE_SET_FORM_STATUS_ACTION
    payload: boolean
}

export type QuestionnaireActionTypes =
    | IQuestionnaireInitilizeAction
    | IQuestionnaireUpdateAction
    | IQuestionnaireRemoveAction
    | IQuestionnaireInsertAction
    | IQuestionnaireMoveAction
    | IQuestionnaireAppendTemplateAction
    | IQuestionnaireAppendIdTemplateAction
    | IQuestionnaireRemoveTemplateAction
    | IQuestionnaireTemplateStatusUpdateAction
    | IQuestionnaireRegisterValidationAction
    | IQuestionnaireSetStatusAction
    | IQuestionnaireFormValidationAction
    | IQuestionnaireFieldFocusAction
    | IQuestionnaireUnregisterValidationAction
    | IQuestionnaireUnregisterAllValidations
    | IQuestionnaireSetFormStatusAction
    | IQuestionnaireReset
