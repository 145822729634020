import React, { useEffect, useState } from "react"
import { IntlProvider } from "react-intl"
import { useDispatch, useSelector } from "react-redux"
import { LoadingPage } from "../pages/_support_/loading/LoadingPage"
import { setTranslations as setTranslationsToStore } from "../store/app-configs/actions"
import { AppState } from "../store"
import { languages } from "../assets/translations/index"

const Intl: React.FC = (props: any) => {
    const dispatch = useDispatch()
    const [translations, setTranslations] = useState({ locale: "", messages: undefined })
    const language = useSelector((state: AppState) => state.appConfigs.language)

    useEffect(() => {
        const fetchData = async (locale: string) => {
            let data: any;
            const hasTranslation = languages.some(l => l.code === locale);
            if (hasTranslation) {
                data = (await import(`../assets/translations/${locale}`)).default
            }
            else {
                data = (await import(`../assets/translations/en`)).default
            }
            dispatch(setTranslationsToStore(data))
            setTranslations({ locale: locale, messages: data })
        }
        fetchData(language || "en")
    }, [language])

    return translations.messages ? (
        <IntlProvider locale={translations.locale} key={translations.locale} messages={translations.messages}>
            {props.children}
        </IntlProvider>
    ) : (
        <LoadingPage />
    )
}

export default Intl
