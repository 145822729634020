import { Draft } from "immer"
import { IFieldValidatorRegister } from "../../../components/questionnaire-core/hooks/useQuestionnaireValidation"
import { QuestionnaireIndexes } from "../../../components/questionnaire-core/templates-questionnaire/interface"
import { IQuestionnaireValidation, IValidationFieldNav, QuestionnaireLevel, QuestionnaireState } from "../types"
import { buildFieldName, buildFieldRoot, getValue, questionnaireValidateField } from "../utils"
import questionnaireReducer from "./questionnaire"

function fieldFocus(draft: Draft<IQuestionnaireValidation>, payload: IValidationFieldNav) {
    const fieldName: string = buildFieldName(payload.name, payload.indexes, payload.level)
    draft.fields[fieldName].params.touched = true
}

function registerValidator(draft: Draft<QuestionnaireState>, payload: IFieldValidatorRegister) {
    const { indexes, level, name } = payload.field
    const fieldName: string = buildFieldName(name, indexes, level)

    draft.validation.fields[fieldName] = {
        params: { validated: false, isValid: true, touched: false, errors: undefined, ...payload },
        nav: { indexes: indexes, name: name, level: level }
    }

    let value = getValue({ categories: draft.categories, ...payload.field })
    if (payload.transformBeforeInitialization != null) {
        try {
            value = payload.transformBeforeInitialization(value)
            questionnaireReducer.updateByLevel(draft, { data: value, field: name, indexes: indexes, level: level })
        } catch (ex) {
            throw ex
        }
    }

    fieldValidation(draft, value, payload.field)
}

function unregisterValidator(draft: Draft<IQuestionnaireValidation>, nav: IValidationFieldNav) {
    const fieldName: string = buildFieldName(nav.name, nav.indexes, nav.level)
    delete draft.fields[fieldName]
}

function unregisterAllValidators(draft: Draft<IQuestionnaireValidation>, root?: string) {
    let keys = Object.keys(draft.fields)
    if (root) {
       keys = keys.filter((field) => field.startsWith(root)) 
    }
    keys.forEach(key => {
        delete draft.fields[key]
    })
}

function formValidation(draft: Draft<QuestionnaireState>) {
    const fields = draft.validation.fields
    // const values = draft.categories;
    Object.values(fields).filter(
        field => !field.params.validated && fieldValidation(draft, getValue({ categories: draft.categories, ...field.nav }), field.nav)
    )
    const invalidQuestionnaireArrays =
        draft.categories == null ||
        draft.categories.length === 0 ||
        draft.categories.filter(
            c =>
                c.subCategories == null ||
                c.subCategories.length === 0 ||
                c.subCategories.filter(s => s.questions == null || s.questions.length === 0).length > 0
        ).length > 0
    const invalidFields = Object.values(fields).filter(field => !field.params.isValid)
    const form = draft.validation.form

    if (invalidFields.length > 0 || invalidQuestionnaireArrays) {
        form.isValid = false
    } else {
        form.isValid = true
    }
    form.submitting = true
}

function fieldValidation(draft: Draft<QuestionnaireState>, value: any, nav: IValidationFieldNav) {
    const { indexes, level, name } = nav
    const fieldName: string = buildFieldName(name, indexes, level)
    const field = draft.validation.fields[fieldName]
    // const value = getValue({ categories: draft.categories, ...nav });

    const errors = questionnaireValidateField(value, field)

    if (field != null) {
        if (errors != null) {
            field.params.errors = errors.join("; ")
            field.params.isValid = false
            field.params.validated = true
        } else {
            field.params.isValid = true
            field.params.validated = true
            field.params.errors = undefined
        }
    }
}

export default {
    fieldFocus,
    registerValidator,
    unregisterValidator,
    formValidation,
    fieldValidation,
    unregisterAllValidators
}
